<template>
  <div>
    <el-card class="box-card" style="margin-bottom: 5px; text-align: center;">
      <div slot="header" class="clearfix" style="font-weight: bold;">
        <span>{{editCustomer.name}}&nbsp;<el-tag style="font-size: 16px;" type="warning"><i class="el-icon-position"></i>&nbsp;{{customerType[editCustomer.type]}}</el-tag></span>
      </div>
      <div class="text item" style="margin-top: 5px;">
        <el-tag style="margin-left: 5px;"
                v-for="(tag, index) in customerCheckedTags"
                :key="tag.name">
          {{tag.name}}
        </el-tag>
      </div>
    </el-card>
    <el-tabs type="border-card">
      <el-tab-pane label="企业资料"><customerEdit :customerId=parseInt(editCustomer.id)></customerEdit></el-tab-pane>
      <el-tab-pane v-if="auth['verifyplan:page']" label="验证计划"><verifyList :customerInfo="editCustomer"></verifyList></el-tab-pane>
      <el-tab-pane label="项目"><projectList :addflag="true" :delflag="true" :customerInfo="editCustomer"></projectList></el-tab-pane>
      <el-tab-pane label="订单"><orderList :customerInfo="editCustomer" :addflag="true"></orderList></el-tab-pane>
      <el-tab-pane v-if="authFollow['followplan:page']" label="跟进">
        <div class="buttons" style="text-align: center; margin-bottom: 10px;">
          <el-button v-if="authFollow['followplan:page']" plain @click="followGridShow='plan'"  v-bind:type="computePlanActiveBtn">计划</el-button>
          <el-button v-if="authFollow['followrecord:page']" plain @click="followGridShow='record'" v-bind:type="computeRecordActiveBtn">记录</el-button>
        </div>
        <followList v-if="authFollow['followplan:page'] && followGridShow=='plan'" :customerInfo="editCustomer" :addflag="true"></followList>
        <follow-record-list v-if="authFollow['followrecord:page'] && followGridShow=='record'" :customer-info="editCustomer"></follow-record-list>
      </el-tab-pane>
<!--      <el-tab-pane label="部门">部门</el-tab-pane>-->
<!--      <el-tab-pane label="员工">员工</el-tab-pane>-->
    </el-tabs>
  </div>
</template>

<script>
import CUS from '../../api/biz/api_customer';
import syscomm from '../../utils/syscomm';
import COMM from '../../api/base/api_comm';
import USER from '../../api/base/api_user';
import customerEdit from './edit';
import verifyList from '../verify/list';
import projectList from '../project/list';
import orderList from '../order/list';
import followList from '../follow/followList';
import followRecordList from '../follow/recordList';

export default {
  name: "customerinfo",
  components: {
    customerEdit,
    verifyList,
    projectList,
    orderList,
    followList,
    followRecordList
  },
  props: {

  },
  data() {
    return {
      editCustomer: {
        id: this.$route.params.customerid
      },
      editTitle: '更新客户数据',
      customerTagList: [],
      customerCheckedTags: [],
      followGridShow: 'plan',
      customerType: {
        0: '潜在', 1: '合作', 2: '流失'
      },
      auth: {},
      authFollow: {}
    }
  },
  // 初始化加载
  mounted() {
    this.init();
  },
  computed: {
    computePlanActiveBtn() {
      return this.followGridShow == 'plan'
          ? "primary":"";
    },
    computeRecordActiveBtn() {
      return this.followGridShow == 'record'
          ? "primary":"";
    }
  },
  // 方法定义
  methods: {
    init() {
      this.init_base();
      this.auth=this.$getAuth('/customer');
      this.authFollow=this.$getAuth('/followList');
      this.initPageData();
    },

    // 加载初始数据支持
    init_base(){
      COMM.getObjType({type: 'customertag'}).then((res) => {
        this.customerTagList = res;
      });
    },

    // 加载页面数据
    initPageData() {
      //this.showLoading = true;
      // 执行查询
      CUS.get({
        id: this.$route.params.customerid,
        type: this.projectType
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          this.editCustomer = res.data;
          this.buildCustomerTags(this.editCustomer);
        } else {
          this.$message.error(res.msg);
          this.initAddData();
        }
      });
    },

    buildCustomerTags(customerInfo) {
      if (customerInfo == null) {
        return;
      }

      let tag = customerInfo.tag;
      for (const index in this.customerTagList) {
        let customerTag = this.customerTagList[index];
        if (tag.indexOf(customerTag['value'] + ',') >= 0) {
          this.customerCheckedTags.push(customerTag);
        }
      }
    }

  }
}
</script>

<style scoped>

</style>