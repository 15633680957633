<template>
  <div>
<!--    <div class="pageHeader" style="overflow: hidden">-->
<!--      <div style="float:left">-->
<!--        <el-button type="primary" icon="el-icon-plus" @click="handle_add()">添加</el-button>-->
<!--        <el-button type="success" icon="el-icon-refresh" @click="initPageData()">刷新</el-button>-->
<!--      </div>-->
<!--      <div style="float:right;">-->
<!--        <el-input placeholder="验证计划名称" v-model="verifykeyword" class="input-with-select" style="width:350px;"></el-input>&nbsp;-->
<!--        <el-button type="primary" @click="handle_search()" icon="el-icon-search">搜索</el-button>-->
<!--      </div>-->
<!--    </div>-->
    <el-row style="margin-bottom: 5px; display: block">
      <div style="float:left;">
        <el-button v-if="auth['verifyplan:add']" type="primary" icon="el-icon-plus" @click="handle_add()">添加</el-button>
        <el-button type="success" icon="el-icon-refresh" @click="initPageData()">刷新</el-button>
      </div>
      <div style="float:right;">
        <el-input placeholder="验证计划名称" v-model="verifykeyword" class="input-with-select" style="width:350px;"></el-input>&nbsp;
        <el-button type="primary" @click="handle_search()" icon="el-icon-search">搜索</el-button>
      </div>
    </el-row>

    <el-table :data="page.list" v-loading="showLoading" element-loading-text="拼命加载中" border width="width:100%; height:50%">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column type="no" label="编号" width="200"></el-table-column>
      <el-table-column prop="name" label="验证计划名称" width="300"></el-table-column>
      <el-table-column prop="custid" label="客户" width="200"></el-table-column>
      <el-table-column prop="status" label="状态" width="100"></el-table-column>
      <el-table-column prop="scope" label="操作" min-width="100">
        <template  #default="scope">
          <el-button v-if="auth['verifyplan:edit']" type="text" icon="el-icon-view" @click="handle_edit(scope.row)">修改</el-button>
          <el-button v-if="auth['verifyplan:del']" type="text" icon="el-icon-delete" class="red" @click="handle_del(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="devlogChange"
                   :current-page="page.pageNum"
                   :page-size="page.pageSize" layout="total, prev, pager, next, jumper"
                   :total="page.total">
    </el-pagination>
  </div>
</template>

<script>
import VERIFY from '../../api/biz/api_verifyplan';
import syscomm from '../../utils/syscomm';
import COMM from "../../api/base/api_comm";
import CUS from "@/api/biz/api_customer";

export default {
  name: "verifyplanlist", // 验证计划列表
  props: {
    customerInfo: {
      type: Object
    },
    verifykeyword: {
      type: String
    }
  },
  data() {
    return {
      showLoading: false,
      page: {list: [], pageNum: 1, pageSize: 10, total: 0},
      auth: {}
    }
  },
  // 初始化加载
  mounted() {
    this.init();
  },
  // 方法定义
  methods: {
    init() {
      this.auth=this.$getAuth('/customer');
      this.initPageData();
    },

    // 加载页面数据
    initPageData() {
      this.showLoading = true;

      // 执行查询
      VERIFY.list({
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        custid: this.customerInfo.id,
        keyword: this.verifykeyword
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          var data = res.pageData;
          this.page.total = data.total;
          this.page.list = data.list;
        } else {
          this.$message.error(res.msg);
          this.page.list = [];
        }
      });
    },

    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    },

    // 查询
    handle_search() {
      this.initPageData();
    },

    // 新增
    handle_add(row) {
      //this.$router.push({ name: 'verifyPlanEdit', params: { custid: this.customerInfo.id } });
      let verifyId = null;
      this.$router.push({path: '/verifyPlanEdit/' + this.customerInfo.id +  '/' + verifyId});
    },

    // 新增
    handle_edit(row) {
      //this.$router.push({ name: 'verifyPlanEdit', params: { custid: this.customerInfo.id } });
      this.$router.push({path: '/verifyPlanEdit/' + this.customerInfo.id +  '/' + row.id});
    },

    // 新增
    handle_del(index, row) {
      this.$confirm('此操作将删除[' + row.name + '] 验证计划, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        VERIFY.delete({id: row.id}).then((res) => {
          if (200 == res.code) {
            this.$message.success("操作成功！");
            this.initPageData();
          } else {
            this.$message.error(res.msg);
          }
        });
      }).catch(() => {
      });
    }

  }
}
</script>

<style scoped>
  .pageHeader {
    background-color: white;
    border-bottom: 1px solid #CCCCCC;
    font-size: 18px;
    padding: 5px 10px;
  }

  .red {
    color: #ff0000;
  }
</style>